<template>
  <div id="app" class="container">
    <app-header v-if="!oauth"/>
    <div id="content" class="mb-5">
      <router-view/>
      <flash-message></flash-message>
    </div>
    <footer v-if="!oauth" class="d-flex align-items-end flex-column text-light">
      <version/>
    </footer>
  </div>
</template>

<script>
import {
  USER_REQUEST
} from '@/vuex/actions/user';
import {ROUTE_NAME_OAUTH} from './router';

import {
  mapGetters
} from 'vuex';
import AppHeader from '@/components/AppHeader';
import Version from '@/components/Version';

export default {
  name: 'app',
  components: {
    AppHeader,
    Version
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    oauth() {
      return this.$route.name === ROUTE_NAME_OAUTH
    },
  },
  created: function () {
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

footer {
  background-color: #0d457a;
  flex: 0 0 30px; /*or just height:30px;*/
  margin-top: auto;
  width: 100%;
}
</style>
