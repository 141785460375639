import {USER_SUCCESS, USER_REQUEST} from '../actions/user';

import get from '@/axios';

const state = {
    id: ''
};

const getters = {
    getUsername: state => state.id,
};

const actions = {
    [USER_REQUEST]: ({commit}) => {
        return new Promise((resolve, reject) => {
            get('/api/userinfo')
                .then(response => {
                    commit(USER_SUCCESS, response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

const mutations = {
    [USER_SUCCESS]: (state, data) => {
        state.id = data.id;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
