import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from '../actions/auth';
import {USER_REQUEST} from '../actions/user';

import axios from '@/axios';

const state = {
    tokenKey: 'rcycle_token',
    token: localStorage.getItem('rcycle_token') || '',
    status: ''
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios
                .post('/api/login', user)
                .then(response => {
                    const token = response.data.accessToken;
                    localStorage.setItem(state.tokenKey, token);
                    commit(AUTH_SUCCESS, token);
                    dispatch(USER_REQUEST);
                    resolve(response);
                })
                .catch(error => {
                    commit(AUTH_ERROR);
                    localStorage.removeItem(state.tokenKey);
                    reject(error);
                });
        });
    },
    [AUTH_LOGOUT]: ({commit}) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem(state.tokenKey);
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, token) => {
        state.status = 'success';
        state.token = token;
    },
    [AUTH_ERROR]: state => {
        state.status = 'error';
    },
    [AUTH_LOGOUT]: state => {
        state.token = '';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
