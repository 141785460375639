import axios from 'axios';
import store from '@/vuex';

import { AUTH_LOGOUT } from '@/vuex/actions/auth';

const $axios = axios.create();
$axios.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem(store.state.auth.tokenKey);
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    function(err) {
        return Promise.reject(err);
    }
);

$axios.interceptors.response.use(undefined, function(err) {
    return new Promise(function() {
        if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
        ) {
            store.dispatch(AUTH_LOGOUT);
        }
        throw err;
    });
});

export default $axios;
