import Vue from 'vue';
import Router from 'vue-router';

const Overview = () => import('@/pages/Overview')
const IdentRouting = () => import('@/pages/IdentRouting')
const OauthAuthorize = () => import('@/pages/OauthAuthorize')

Vue.use(Router);

export const ROUTE_NAME_OAUTH = 'OAuth'

const routes = [
    {
        path: '/',
        name: 'Overview',
        component: Overview
    },
    {
        path: '/gtin/:gtin/lot/:ser',
        component: Overview
    },
    {
        path: '/01/:gtin/10/:ser',
        component: Overview
    },
    {
        name: ROUTE_NAME_OAUTH,
        // this is the OAuth2 "authorization endpoint" https://datatracker.ietf.org/doc/html/rfc6749#section-3.1
        path: '/oauth/authorize',
        component: OauthAuthorize
    },
    {
        path: '/ident',
        name: 'Ident-Routing',
        components:{default: IdentRouting},
        children:[
          {
              path: ':id',
              component: IdentRouting
          }
        ]
    }
];

const router = new Router({
    routes,
    mode: 'history'
});

export default router;
