<template>
  <div>
    <b-button name="login" type="button" class="btn-custom" v-if="!isAuthenticated" v-b-modal.login-modal>
      Login
    </b-button>
    <b-button name="logout" type="button" class="btn-custom" v-else-if="!loginOnly" @click="logout">
      Logout
    </b-button>

    <b-modal id="login-modal" title="Login" centered hide-footer>
      <LoginForm />
    </b-modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import {
  AUTH_LOGOUT
} from '@/vuex/actions/auth';
import LoginForm from './LoginForm';

export default {
  name: 'LoginComponent',
  props: {
    loginOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      user: {
        username: '',
        password: ''
      },
      loading: false,
      loginError: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.user.username = "";
        this.user.password = "";
      });
    }
  },
  components: {
    LoginForm
  }
}
</script>

<style scoped>
.btn-custom {
  color: #000000;
  background-color: #FFFFFF;
  border-color: #5794c4;
}
</style>
